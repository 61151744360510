import React from "react";
import ShowLoader from "./summaryloader";

function showShellESummary({ data, loading }) {
    var lines = [];
  if (data.risk_insights_summary !== undefined) {
    lines = data.risk_insights_summary.split("\n");
  }
  return (
    <div>
      {loading ? (
        <div> Generating Summary. Please wait .. !!
           <span className="summary-loader"> <ShowLoader></ShowLoader> </span>


        </div>
      ) : (
        <div className="summaryLoader">
          {data.risk_insights_summary !== undefined ? (
            <div>
              {lines.map((line, index) => {
                if (line === "") {
                  return <br key={index} />;
                } else if (line.includes(":")) {
                  return (
                    <p key={index} style={{ fontWeight: "bold" }}>
                      {line}
                    </p>
                  );
                } else {
                  return <p key={index}>{line}</p>;
                }
              })}
            </div>
          ) : (
            "Loading.."
          )}
        </div>
      )}
    </div>
  );

}
export default showShellESummary;
