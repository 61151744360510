import React from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Searchpage from './app/searchpage';
import MainPage from './app/mainpage';
import Login from './app/login';
import UserControl from './app/user-control';
import ProjectAcronyms from './app/pro-acronyms'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivacyTAndC from './app/privacyTAndC';
import ActivityDashboard from './app/userActivityComponent/activityDashboard';
import MostNotRelevant from './app/userActivityComponent/mostnotrelevant';
import TourGuideBase from './app/tourGuide/tourGuideBase';
import { AppProvider } from './app/context/context';
function App() {
  return (
    <div className="App">
      <AppProvider>
      <TourGuideBase />
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          {/* <Route exact path="/" component={Searchpage} /> */}
          <Route exact path="/search" component={Searchpage} />
          <Route exact path="/dashboard" component={MainPage} />
          <Route exact path="/grid" component={UserControl} />
          <Route exact path="/acronyms" component={ProjectAcronyms} />
          <Route exact path="/acceptance-form" component={PrivacyTAndC} />
          <Route exact path="/user-activity" component={ActivityDashboard} />
          <Route exact path="/most-not-relevant" component={MostNotRelevant} />
        </Switch>
      </Router>
      </AppProvider>
      <ToastContainer position='top-center' newestOnTop="true" />
    </div>
  );
}

export default App;
