import React, { useState, useEffect, useCallback, useRef } from 'react';
import SidebarMenu from './sidebarmenu';
import Search from './search';
import SearchAdvanced from './search-advanced';
import LearnAction from './learnaction';
import getCountryFlag from './countryflag';
import RaceSpinner from './spinner';
import { emptyfunctinalCount, filterLists } from './filter';
import DownloadReport from './download-report';
import ReportModal from './report-modal';
import UserProfile from './user-profile';
import utils from './app-util';
import { advancedSearch, eMoCAdvanceSearch, eMoCFunctionalTagClearFilter, qualityAdvancedSearch } from './advanced-seach';
import heatmapFilterOnCall from './heatmapfilteroncall';
import { graphAnalysis, riskResults, learningResults } from './mainpageTpl';
import LogoBranding from './logo-branding';
import { toast } from 'react-toastify';
import { stackedBarChartFIlterCallbackOnCall, stackedBarChartClearBtnOnClick } from './stackedBarChartFIlterCallbackOnCall';
import { riskDateFilterOnCall, emocDateFilterOnCall, qualityDateFilterOnCall } from './dateFilterOnCall';
import BowtiePopup from './bowtiePopup';
import IdleTimeTracker from './IdleTimeTrack/IdleTimeTracker'
import { loadThematicBowtieData } from './bowtie';
import Emoc from './emocComponent/emoc'
import alternateKeyword from './alternatekeyword';
import { filterEmocResult } from './emocComponent/emocUtils';
import { loadFilteredDataForRiskPlots, updateTECOPCount, updateAdvancedSearchDropdown } from './filter-utils';
import { getSelectedDateRange, getSelectedYearValue } from './date-filter';
import Quality from './qualityComponent/quality';
import { displayAllQualityCard, getFilterResult, qualityFilterOnClear, getVisibleElementsFilterData } from './qualityComponent/QualityFilterUtils';
import { useAppContext, useMount } from './context/context';


const CONTENT_TYPE = 'application/json';


const HM_REFRESH_BTN_SELECTOR = '.heatmap-refresh-btn';
const REFRESH_COST_SELECTOR = '.refresh-plots-for-filter-cost',
    REFRESH_SCHEDULE_SELECTOR = '.refresh-plots-for-filter-schedule',
    REFRESH_PROBABILITY_SELECTOR = '.refresh-plots-for-filter-probability';
const RISK_SIDEBAR = ".risk-sidebar",
    EMOC_SIDEBAR = ".emoc-sidebar",
    QUALITY_SIDEBAR = ".quality-sidebar",
    QUALITY_PLOT_REFRESH = ".refresh-plots-for-filter-quality";

function addToRiskLessonReports(riskOrLessonReport, currentElement, url, setShowReportModal) {
    const refreshDownloadList = document.querySelector(".download-list-refresh");
    const okBtn = document.querySelector('.ok');

    okBtn.disabled = false;
    //Reset the input fields
    const newR = document.querySelector("#newReportName");
    newR.value = "";
    newR.disabled = false;
    const existRN = document.querySelector("#existingname")
    existRN.selectedIndex = 0;
    existRN.disabled = false;
    setShowReportModal(false);
    utils.showCBoxLoader(currentElement);
    fetch(url, {
        method: "POST",
        headers: { 'Content-Type': CONTENT_TYPE, "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") },
        body: riskOrLessonReport
    }).then(res => {
        return utils.checkSessionStatus(res);
    }).then((results) => {
        okBtn.innerText = 'Ok';
        okBtn.disabled = false;
        utils.hideCBoxLoader(currentElement);
        if (results.status) {
            refreshDownloadList.click();
            toast.success(`"${JSON.parse(riskOrLessonReport).reportname}" added successfully`, { position: toast.POSITION.TOP_RIGHT })
        } else {
            currentElement.checked = false;
            toast.warn(`Failed to add the report "${JSON.parse(riskOrLessonReport).reportname}"`, { position: toast.POSITION.TOP_RIGHT });
        }
    }, (_err) => {
        okBtn.innerText = 'Ok';
        okBtn.disabled = false;
        currentElement.checked = false;
        setShowReportModal(false);
        utils.hideCBoxLoader(currentElement);
        toast.warn(`Failed to add the report "${JSON.parse(riskOrLessonReport).reportname}"`, { position: toast.POSITION.TOP_RIGHT });
    });
}
/**
 * 
 * @param {String} val - String value to be searched
 * @param {Object} props - Component properties
 * @param {function} setLoading - Set spinner/Loader value to disaply
 * @param {function} updateStates - The method updates the component states on each query
 * @param {Boolean} iskeywordSearch - Search query submit through keyboard or button press
 * @param {Boolean} mustInclude - Is the query includes 'mustInclude' keyword
 */
function loadQuery(val, props, setLoading, updateStates, iskeywordSearch = false, mustInclude = false) {
    document.querySelector("#sinceyearrisk").value = "";
    document.querySelector(".date-filter-wrapper.risk .react-daterange-picker__clear-button").click();
    document.querySelector("#sinceyearemoc").value = "";
    document.querySelector(".date-filter-wrapper.emoc .react-daterange-picker__clear-button").click();
    document.querySelector("#sinceyearquality").value = "";
    document.querySelector(".date-filter-wrapper.quality .react-daterange-picker__clear-button").click();
    const clearcharacteristics = document.querySelector(".quality-custom-autocomplete.characteristics .MuiAutocomplete-clearIndicator");
    const cleardisciplinefunction = document.querySelector(".quality-custom-autocomplete.disciplinefunction .MuiAutocomplete-clearIndicator");
    const clearequipmenttype = document.querySelector(".quality-custom-autocomplete.equipmenttype .MuiAutocomplete-clearIndicator");
    if(clearcharacteristics) {
        clearcharacteristics.click();
    }
    if (cleardisciplinefunction) {
        clearcharacteristics.click();
    }
    if (clearequipmenttype) {
        clearequipmenttype.click();
    }
    setLoading(true);
    const mustIncludeVal = document.querySelector(utils.elementSelectors.MUST_INCLUDE_SELECTOR).value;
    let url = "";
    if (mustInclude) {
        url = `/query_results/${val}`;
    } else if (mustIncludeVal !== '') {
        window.sessionStorage.setItem('_prevquery', val);
        val = `${val}/${mustIncludeVal}`;
        url = `/query_results/${val}`;
    } else {
        url = `/query_results/${val}`;
    }
    fetch(url, {
        headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
    })
        .then(res => {
            return utils.checkSessionStatus(res);
        })
        .then(
            (results) => {
                setLoading(false);
                if (Object.keys(results.risk).length > 1 || Object.keys(results.lesson).length > 1 || Object.keys(results.emoc).length > 1) {

                    // As per new requirement mustinclude keyword should not be appended to the search query
                    val = mustIncludeVal !== '' ? val.split('/')[0] : val;
                    window.sessionStorage.setItem("query", val);

                    props.location.state.query = val
                    updateStates(results, val);
                } else {
                    setLoading(false);
                    iskeywordSearch ? toast.warn("Selected search term doesn't fetch any results.") : toast.warn("Query doesn't fetch any results, please rephrase.");
                    document.querySelector(utils.elementSelectors.SEARCH_FIELD_SELECTOR).value = window.sessionStorage.getItem("query");
                }
            },
            (_err) => {
                setLoading(false);
                toast.warn("Query doesn't fetch any results, please rephrase.");
            }
        )
}
function loadHM() {
    const hmRefresh = document.querySelector(HM_REFRESH_BTN_SELECTOR);
    setTimeout(() => {
        if (hmRefresh !== null) {
            hmRefresh.click();
        }
    }, 1000);
}

function getSummaryDataForShellE(riskIDs,searchQuery,setShellESummaryData,setShellESummaryLoading,shellESummaryLoading) 
{
    if  (!shellESummaryLoading)
    {
        setShellESummaryLoading(true);
        const summaryUrl=`/get_risk_insights_summary/${searchQuery}/${JSON.stringify(riskIDs)}`;
        fetch(summaryUrl, { headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") } })
        .then(function (response) {
            setShellESummaryLoading(false);
            if (response.status !== 200) {
                console.log('Error Status Code: ' + response.status);
            } else {
                response.json().then(function (resultResponse) {
                    setShellESummaryData(resultResponse);
                });
            }
        }).catch(function (err) {
            console.log('Error Status Code: ' + err);
        });
    }
    else{
        console.log("Summary data already loading..");
    
    }
}

function setHeatmapgraphData(_data) {
    console.log("This callback is not in use")
}

function resetFunctoinalCheckBox() {
    const list = document.querySelectorAll(".filter-list input:checked");
    list.forEach((element) => {
        element.checked = false;
    });
}

export default function MainPage(props) {
    const items = filterLists();
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(props.location.state.results);

    const [adminInfo] = useState({isAdmin: props.location.state.admin, isAppOwner: props.location.state.appOwner, otheruser:props.location.state.otheruser});

    const [projects, setProjects] = useState([...new Set(props.location.state.results.risk.risk_org)]);
    const [countries, setCountries] = useState([...new Set(props.location.state.results.risk.risk_loc)]);
    const [lob, setLob] = useState([...new Set(props.location.state.results.risk.lob)]);
    const [emocProjectCountryLoB, setEmocProjectCountryLoB] = useState({
        lob: [...new Set(props.location.state.results.emoc.lob)],
        projects: [...new Set(props.location.state.results.emoc.emoc_org)],
        countries: [...new Set(props.location.state.results.emoc.emoc_loc)]
    })

    const [learnActionInfo, setLearnActionInfo] = useState({ display: false, id: "", title: "", query: "", datatype: "" });

    const [riskkw, setRiskkw] = useState(props.location.state.results.risk.keywords);
    const [learningkw, setLearningkw] = useState(props.location.state.results.lesson.keywords);
    const [emockw, setEmockw] = useState(props.location.state.results.emoc.keywords);
    // const [riskSummary, setRiskSummary] = useState(props.location.state.results.risk.risk_summary);
    const [costgraph, setCostgraph] = useState(utils.formatGraphData(props.location.state.results.risk.cost_graph));
    const [schedulegraph, setSchedulegraph] = useState(utils.formatGraphData(props.location.state.results.risk.schedule_graph));
    const [probabilitygraph, setProbabilitygraph] =useState(utils.formatGraphData(props.location.state.results.risk.probability_graph));
     const [heatmapgraph] = useState(props.location.state.heatMap);
    const [riskids] = useState(props.location.state.results.risk.risk_id);
    const [shellESummary,setShellESummary]=useState({});
    const [shellESummaryLoading,setShellESummaryLoading]=useState(false);
    const [filterCountsAndStatus, setFilterCountsAndStatus] = useState({
        searchresultcount: props.location.state.results.risk.risk_id.length,
        riskFunctionalCount: props.location.state.results.risk.risk_functional_count,
        isAdvancedTECOPSearchOn: false,
        isHeatmapFilterOn: false,
    })
    const [emocdata, setEmocdata] = useState(utils.formatEmocData(props.location.state.results.emoc));
    const [qualitydata, setQualitydata] = useState(utils.formatQualityData(props.location.state.results.quality, getCountryFlag));
    // =======TourGuide Initialization===========
    const generateRportBtnRef = useRef(null);
    const { setState, state: { tourActive },} = useAppContext();
    useMount(()=>{
        setState({generateBtnRef: generateRportBtnRef.current});
        if(tourActive){
            setState({ run: true});
        }
    });
    // =======TourGuide Initialization End===========
    useEffect(() => {
        document.querySelector(utils.elementSelectors.SEARCH_FIELD_SELECTOR).value = props.location.state.query;
        window.sessionStorage.setItem("query", props.location.state.query);
        // plot menu clear button on click
        document.querySelector(".cost-c-btn").addEventListener('click', stackedBarChartClearBtnClick);
        document.querySelector(".schedule-c-btn").addEventListener('click', stackedBarChartClearBtnClick);
        document.querySelector(".probability-c-btn").addEventListener('click', stackedBarChartClearBtnClick);
        document.querySelector(REFRESH_COST_SELECTOR).addEventListener('click', refreshPlotsForFilterCost);
        document.querySelector(REFRESH_SCHEDULE_SELECTOR).addEventListener('click', refreshPlotsForFilterSchedule);
        document.querySelector(REFRESH_PROBABILITY_SELECTOR).addEventListener('click', refreshPlotsForFilterProbability);
        document.querySelector("#heatmap-tab").addEventListener('click', heatmapTabOnClick);
        sessionStorage.setItem("emocTab", JSON.stringify({newload: true, otclicked:false, stclicked: false}));
        sessionStorage.setItem("qualityTab", JSON.stringify({newload: true, otclicked:false, stclicked: false}));
       getSummaryDataForShellE(props.location.state.results.risk.risk_id,props.location.state.query,setShellESummary,setShellESummaryLoading,shellESummaryLoading);
   
    }, [props.location.state.query]);
    function refreshPlotsForFilterCost() {
        loadFilteredDataForRiskPlots("get-filtered-cost-plots", setCostgraph);
    }
    function refreshPlotsForFilterSchedule() {
        loadFilteredDataForRiskPlots("get-filtered-schedule-plots", setSchedulegraph);
    }
    function refreshPlotsForFilterProbability() {
        loadFilteredDataForRiskPlots("get-filtered-probability-plots", setProbabilitygraph);
    }
    function heatmapTabOnClick() {
        if (document.querySelector(".risk-graph-analysis .tab-pane.graph.active svg").classList.contains('clickactive')) {
            stackedBarChartFIlterCallbackOnCall( { id: "", filter: false }, filterCountsAndStatus, setFilterCountsAndStatus, OnHeatmapClick_ClearAll);
        }
    }
    const handleSearchOnKeyDown = (inputVal) => {
        document.querySelector(utils.elementSelectors.MUST_INCLUDE_SELECTOR).value = "";
        if (inputVal !== "") {
            const val = inputVal;
            loadQuery(val, props, setLoading, updateStates);
        }
    }
    function keyWordsQueryCallback(data) {
        setLoading(true);
        //Fetch the new search query using the params
        fetch(`/new_query_from_keyword?query=${data.query}&back_value=${data.back}&front_value=${data.face}`, {
            headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
        })
            .then(res => {
                if (res.status !== 200) {
                    utils.checkSessionStatus(res);
                }
                return res.text();
            })
            .then(
                (results) => {
                    props.location.state.query = results;
                    document.querySelector(utils.elementSelectors.SEARCH_FIELD_SELECTOR).value = results;
                    loadQuery(results, props, setLoading, updateStates, true);
                },
                (_err) => {
                    toast.warn("Selected search term doesn't fetch any results.");
                }
            )
    }

    function mustIncludeCallback(data) {
        loadQuery(`${document.querySelector(utils.elementSelectors.SEARCH_FIELD_SELECTOR).value}/${data}`, props, setLoading, updateStates, false, true);
    }
    function updateStates(results, query) {
        const dataformated = utils.formatData(results, getCountryFlag);
        query = query.includes("/") ? query.split("/")[0] : query;
        props.history.replace({ pathname: "/dashboard", state: { query: query, results: dataformated, heatMap: heatmapgraph } });
        setResult(dataformated);
        setEmocdata(results.emoc);
        setProjects([...new Set(results.risk.risk_org)]);
        setCountries([...new Set(results.risk.risk_loc)]);
        setLob([...new Set(results.risk.lob)]);
        setRiskkw(results.risk.keywords);
        setLearningkw(results.lesson.keywords);
        setEmockw(results.emoc.keywords)
        setCostgraph(utils.formatGraphData(results.risk.cost_graph));
        setSchedulegraph(utils.formatGraphData(results.risk.schedule_graph));
        setProbabilitygraph(utils.formatGraphData(results.risk.probability_graph));
        
        getSummaryDataForShellE(results.risk.risk_id,query,setShellESummary,setShellESummaryLoading,shellESummaryLoading);
        
        // setEmocdata(results.emoc);
        setEmocProjectCountryLoB({
            lob: [...new Set(results.emoc.lob)],
            projects: [...new Set(results.emoc.emoc_org)],
            countries: [...new Set(results.emoc.emoc_loc)]
        });
        resetFunctoinalCheckBox();
        setFilterCountEmoc(_filterCountEmoc => ({ ..._filterCountEmoc, emocCount: results.emoc.emoc_id.length }));
        if (Object.keys(results.risk.risk_functional_count).length > 0) {
            setFilterCountsAndStatus(_filterCountsAndStatus => (
                { ..._filterCountsAndStatus, searchresultcount: results.risk.risk_id.length, riskFunctionalCount: results.risk.risk_functional_count })
            );
        } else {
            setFilterCountsAndStatus(_filterCountsAndStatus => (
                { ..._filterCountsAndStatus, searchresultcount: results.risk.risk_id.length, riskFunctionalCount: emptyfunctinalCount })
            );
        }
        setQualityProjectCountryLoB({
            lob: [...new Set(results.quality.quality_lob)],
            projects: [...new Set(results.quality.quality_org)],
            countries: [...new Set(results.quality.quality_loc)]
        });
        
        setQualitydata(utils.formatQualityData(results.quality, getCountryFlag));
        setQualityFilterData({
            quality_characteristics: results.quality.quality_characteristics,
            quality_discipline_function: results.quality.quality_discipline_function,
            quality_equipment_type: results.quality.quality_equipment_type
        });
        setFilterCountQuality(qualityCount => ({ ...qualityCount, qualityCount: results.quality.quality_id.length}));
        sessionStorage.setItem("indices", JSON.stringify(results.risk.risk_id));
        sessionStorage.setItem("indicesemoc", JSON.stringify(results.emoc.emoc_id));
        sessionStorage.setItem("indicesquality", JSON.stringify(results.quality.quality_id));
        document.querySelector(".xaxis-dropdown-select").selectedIndex = 0;
        document.querySelector(".value-dropdown-select").selectedIndex = 0;
        clearAdvancedSearch();
        const hmRefresh = document.querySelector(HM_REFRESH_BTN_SELECTOR);
        setTimeout(() => {
            hmRefresh.click();
        }, 1000);
    }
    window.removeEventListener('load', loadHM);
    window.addEventListener('load', loadHM);

    function clearAdvancedSearch() {
        advancedSearch({ projects: [], countries: [], lobs: [] }, filterCountsAndStatus, setFilterCountsAndStatus, setFilterValue, updateDropdownList);
        const lists = document.querySelectorAll(".advanced-search-wrapper .dropdown-filtering .chiplist .chip")
        lists.forEach(function (element) {
            element.remove();
        });
    }

    function callBack(learnaction) {
        if (learnaction.type !== 'Action') {
            setLearnActionInfo({ display: true, id: learnaction.id, title: learnaction.type, query: learnaction.query, datatype: learnaction.datatype});
        } else {
            bowtieCallback(learnaction)
        }

    }
    const [bowtieoption, setBowtieoption] = useState({ show: false, thematic: false, data: {}, dataid: "", datatype:"" });
    function bowtieCallback(data) {
        setLoading(true);
        fetch(`/${data.bowtieURL}/${data.id}`, {
            method: "GET",
            headers: { 'Content-Type': 'application/json', "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
        }).then(res => {
            return utils.checkSessionStatus(res);
        }).then((results) => {
            setLoading(false);
            setBowtieoption({ show: true, thematic: false, data: results, dataid: data.id, datatype: data.datatype });
        }, (err) => {
            setLoading(false);
            console.log(err);
        });
    }
    function bowtieAfterClose(isClosed) {
        setBowtieoption({ show: isClosed, thematic: false, data: {}, dataid: "", datatype:"" });
        document.getElementById("thematicbowtie-tab")?.classList.remove('active');
        const activeNavTabId = document.querySelector(".risk-graph-analysis .tab-pane.active").getAttribute("aria-labelledby");
        document.querySelector(`.risk-graph-analysis .nav.nav-tabs .nav-link#${activeNavTabId}`)?.classList.add("active");
    }
    function riskPlotTabsOnClick(_e) {
        loadThematicBowtieData(setBowtieoption, sessionStorage.getItem('query'), setLoading);
    }
    function callBackLearnActionToClose(_msg) {
        setLearnActionInfo({...learnActionInfo, display: false});
    }

    function advanceSearchCallback(data, iseMoC, isQuality) {
        if (iseMoC) {
            sessionStorage.setItem("emocTab", JSON.stringify({newload: true, otclicked:false, stclicked: false}));
            eMoCAdvanceSearch(data, filterCountEmoc, setFilterCountEmoc, updateDropdownList);
        } else if (isQuality) {
            sessionStorage.setItem("qualityTab", JSON.stringify({newload: true, otclicked:false, stclicked: false}));
            qualityAdvancedSearch(data, filterCountQuality, setFilterCountQuality, updateDropdownList, setQualityFilterData);
        } else {
            advancedSearch(data, filterCountsAndStatus, setFilterCountsAndStatus, setFilterValue, updateDropdownList);
        }
    }

    function qualityFilterDropdownUpdate(selectedDropdown, values) {
        if (selectedDropdown === 'lobs') {
            setQualityProjectCountryLoB({ ...qualityProjectCountryLoB, countries: values.countriess, projects: values.projectss });
        } else if (selectedDropdown === 'countries') {
            setQualityProjectCountryLoB({ ...qualityProjectCountryLoB, lob: values.lobss, projects: values.projectss });
        } else if (selectedDropdown === 'projects') {
            setQualityProjectCountryLoB({ ...qualityProjectCountryLoB, lob: values.lobss, countries: values.countriess });
        } else {
            setQualityProjectCountryLoB({ lob: values.lobss, projects: values.projectss, countries: values.countriess });
        }
    }
    
    function eMoCFilterDropdownUpdate(selectedDropdown, values) {
        if (selectedDropdown === 'lobs') {
            setEmocProjectCountryLoB({ ...emocProjectCountryLoB, countries: values.countriess, projects: values.projectss });
        } else if (selectedDropdown === 'countries') {
            setEmocProjectCountryLoB({ ...emocProjectCountryLoB, lob: values.lobss, projects: values.projectss });
        } else if (selectedDropdown === 'projects') {
            setEmocProjectCountryLoB({ ...emocProjectCountryLoB, lob: values.lobss, countries: values.countriess });
        } else {
            setEmocProjectCountryLoB({ lob: values.lobss, projects: values.projectss, countries: values.countriess });
        }
    }

    function riskFilterDropdownUpdate(selectedDropdown, values) {
        if (selectedDropdown !== 'lobs') {
            setLob(values.lobss);
        }
        if (selectedDropdown !== 'countries') {
            setCountries(values.countriess);
        }
        if (selectedDropdown !== 'projects') {
            setProjects(values.projectss);
        }
    }

    function updateDropdownList(selectedDropdown, values) {
        if (emocAdvanceFilter) {
            eMoCFilterDropdownUpdate(selectedDropdown, values);
        } else if (qualityAdvanceFilter){
            qualityFilterDropdownUpdate(selectedDropdown, values);
        } else {
            riskFilterDropdownUpdate(selectedDropdown, values);
        }
    }

    function setFilterValue(_data, resetFilter = false) {
        setFilterCountsAndStatus(_filterCountsAndStatus => ({ ..._filterCountsAndStatus, isAdvancedTECOPSearchOn: true }));
        if (filterCountsAndStatus.isHeatmapFilterOn) {
            utils.clearHeatMapFilter();
        }

        setFilterCountsAndStatus(_filterCountsAndStatus => ({ ..._filterCountsAndStatus, isHeatmapFilterOn: false }));
        let count = 0;
        const filterVals = [], advancedSearchDropdownValues = { projectss: [], countriess: [], lobss: [] };
        const list = document.querySelectorAll(utils.elementSelectors.FILTER_LIST_INP + ':checked');
        list.forEach(function (element) {
            // This is for the inputs which are not getting deselected where the count value is zero
            if (element.dataset.number === "0" && element.checked) {
                element.checked = false;
            }
            if (element.checked) {
                filterVals.push(element.value);
            }
        });

        const listTobeFilter = [], advSearch = [];
        const advSearchElem = document.querySelectorAll(utils.elementSelectors.CHIP_LIST_SELECTOR);
        advSearchElem.forEach(function (element) {
            advSearch.push(element.dataset.item);
        });
        const advSearchKeylength = advSearch.length;
        const yearValue = getSelectedYearValue('risk');
        const dateRangeValue = getSelectedDateRange('risk');
        const cards = document.querySelectorAll(utils.elementSelectors.SR_CARD_SELECTOR);
        cards.forEach(function (element) {
            //if called from advanced search where dropdown select list not empty and need to repopulate based on functional filter
            if (resetFilter) {
                listTobeFilter.push(element)
            } else {
                if (advSearchKeylength > 0) {
                    advSearch.forEach(function (item) {
                        if (element.classList.contains(item)) {
                            listTobeFilter.push(element);
                        }
                    });
                }
                // If Year filter is active select the list to be filter
                else if (yearValue !== "") {
                    if (new Date(parseInt(element.dataset.modified, 10)).getFullYear() === parseInt(yearValue, 10)) {
                        listTobeFilter.push(element);
                    }
                }
                // If date range filter is active select the list to be filter
                else if (dateRangeValue.length > 0) {
                    if (utils.isBetweenDate(parseInt(element.dataset.modified, 10), dateRangeValue[0].getTime(), dateRangeValue[1].getTime())) {
                        listTobeFilter.push(element);
                    }
                } else {
                    listTobeFilter.push(element)
                }
            }
        });

        const show = [];
        if (filterVals.length > 0) {
            listTobeFilter.forEach(function (element) {
                for (let i = 0; i < filterVals.length; ++i) {
                    if (utils.hasClass(element, filterVals[i].replace(/\s|,|\//g, '_'))) {
                        show.push(element.dataset.id)
                    }
                }
            })
            if (show.length > 0) {
                listTobeFilter.forEach(function (element) {
                    if (show.includes(element.dataset.id)) {
                        element.classList.remove('d-none');
                        element.classList.add('d-block');
                        count += 1;
                        if (element.dataset.lob !== "") {
                            advancedSearchDropdownValues.lobss.push(element.dataset.lob);
                        }
                        if (element.dataset.country !== "") {
                            advancedSearchDropdownValues.countriess.push(element.dataset.country);
                        }
                        if (element.dataset.project !== "") {
                            advancedSearchDropdownValues.projectss.push(element.dataset.project);
                        }
                    } else {
                        element.classList.add('d-none');
                        element.classList.remove('d-block');
                    }
                });
                for (const key in advancedSearchDropdownValues) {
                    if (advancedSearchDropdownValues.hasOwnProperty(key)) {
                        advancedSearchDropdownValues[key] = utils.removeDuplicate(advancedSearchDropdownValues[key]);
                    }
                }
                updateDropdownList("", advancedSearchDropdownValues);
            } else {
                cards.forEach(function (element) {
                    element.classList.remove('d-block');
                    element.classList.add('d-none');
                });
            }

            setFilterCountsAndStatus(_filterCountsAndStatus => ({ ..._filterCountsAndStatus, searchresultcount: count }));
        } else {
            if (advSearchKeylength > 0) {
                advancedSearch(utils.getSelectedAdvanced(), filterCountsAndStatus, setFilterCountsAndStatus, setFilterValue, updateDropdownList);
            }
            // If the TECOP checkbox unchecked when date range filter or year filter is active
            else if (yearValue !== "" || dateRangeValue.length > 0) {
                listTobeFilter.forEach(function (element) {
                    count += 1;
                    element.classList.remove('d-none');
                    element.classList.add('d-block');
                });
                setFilterCountsAndStatus(_filterCountsAndStatus => ({ ..._filterCountsAndStatus, searchresultcount: count, riskFunctionalCount: updateTECOPCount(listTobeFilter) }));
            } else {
                const project = [], _countries = [], lobs = [];
                cards.forEach(function (element) {
                    count += 1;
                    element.classList.remove('d-none');
                    element.classList.add('d-block');

                    if (element.dataset.project !== '') {
                        project.push(element.dataset.project);
                    }
                    if (element.dataset.country !== '') {
                        _countries.push(element.dataset.country);
                    }
                    if (element.dataset.lob !== '') {
                        lobs.push(element.dataset.lob);
                    }
                });
                setLob([...new Set(lobs)])
                setCountries([...new Set(_countries)])
                setProjects([...new Set(project)])

                setFilterCountsAndStatus(_filterCountsAndStatus => ({ ..._filterCountsAndStatus, searchresultcount: count }));
            }
        }
        setTimeout(() => {
            document.querySelector(REFRESH_COST_SELECTOR).click();
            document.querySelector(REFRESH_SCHEDULE_SELECTOR).click();
            document.querySelector(REFRESH_PROBABILITY_SELECTOR).click();
            document.querySelector(REFRESH_PROBABILITY_SELECTOR).click();
            document.querySelector(HM_REFRESH_BTN_SELECTOR).click();
        }, 2000);
    }
    function uncheckIndeterminateInput() {
        const list = document.querySelectorAll(utils.elementSelectors.FILTER_LIST_INP);
        list.forEach(function (element) {
            if (element.checked || element.indeterminate) {
                element.indeterminate = false;
                element.checked = false;
            }
        });
    }
    function functionalTagClearFilter(isCalledFromOnHeatmapClick_ClearAll=false) {
        document.querySelector(".date-filter-wrapper.risk #sinceyearrisk").value = '';
        document.querySelector(".date-filter-wrapper.risk .react-daterange-picker__clear-button").click();
        let count = 0;
        const advancedFilterTag = document.querySelectorAll(utils.elementSelectors.CHIP_LIST_SELECTOR);
        if (advancedFilterTag.length > 0) {
            uncheckIndeterminateInput();
            advancedSearch(utils.getSelectedAdvanced(), filterCountsAndStatus, setFilterCountsAndStatus, setFilterValue, updateDropdownList, isCalledFromOnHeatmapClick_ClearAll);
        } else {
            uncheckIndeterminateInput();
            const cards = document.querySelectorAll(utils.elementSelectors.SR_CARD_SELECTOR);
            cards.forEach(function (element) {
                count += 1;
                element.classList.remove('d-none');
                element.classList.add('d-block');
            });

            setFilterCountsAndStatus(filterCountsAndStatuss => ({ ...filterCountsAndStatuss, searchresultcount: count, riskFunctionalCount: result.risk.risk_functional_count }));
            if(!isCalledFromOnHeatmapClick_ClearAll){
                setTimeout(() => {
                    updateAdvancedSearchDropdown(document.querySelectorAll('.risk-item.d-block'), riskFilterDropdownUpdate);
                    document.querySelector(REFRESH_COST_SELECTOR).click();
                    document.querySelector(REFRESH_SCHEDULE_SELECTOR).click();
                    document.querySelector(REFRESH_PROBABILITY_SELECTOR).click();
                    document.querySelector(REFRESH_PROBABILITY_SELECTOR).click();
                    document.querySelector(HM_REFRESH_BTN_SELECTOR).click();
                }, 2000);
            }
        }
    }

    const [showlearningKW, setShowlearningKW] = useState("risks");
    const [emocAdvanceFilter, setEmocAdvanceFilter] = useState(false);
    const [qualityAdvanceFilter, setQualityAdvanceFilter] = useState(false);
    function mainTabsOnClick(e) {
        const sidebarElem = document.querySelector(".sidebar-container")
        const advancedSearchDropdownFilter = document.querySelector('.dropdown-filtering.active');
        if (e.target.id === 'risk-tab') {
            sessionStorage.setItem("emocTab", JSON.stringify({newload: false, otclicked:true, stclicked: false}));
            sessionStorage.setItem("qualityTab", JSON.stringify({newload: false, otclicked:true, stclicked: false}));
            advancedSearchDropdownFilter.classList.remove('d-none'); //Display adv. search dropdown filter
            setShowlearningKW("risks");
            sidebarElem.style.pointerEvents = "all";
            sidebarElem.style.opacity = "1";
            document.querySelector(RISK_SIDEBAR).style.display = 'block';
            document.querySelector(EMOC_SIDEBAR).style.display = 'none';
            document.querySelector(QUALITY_SIDEBAR).style.display = 'none';
            setEmocAdvanceFilter(false);
            setQualityAdvanceFilter(false);
            utils.pageView('/pageview/risk');
        } else if (e.target.id === 'learnings-tab') {
            sessionStorage.setItem("emocTab", JSON.stringify({newload: false, otclicked:true, stclicked: false}));
            sessionStorage.setItem("qualityTab", JSON.stringify({newload: false, otclicked:true, stclicked: false}));
            advancedSearchDropdownFilter.classList.add('d-none'); //Hide adv. search dropdown filter
            setShowlearningKW("learnings");
            sidebarElem.style.pointerEvents = "none";
            sidebarElem.style.opacity = "0.2";
            utils.pageView('/pageview/learning');
        } else if(e.target.id === 'emoc-tab'){
            sessionStorage.setItem("emocTab", JSON.stringify({newload: false, otclicked:false, stclicked: true}));
            sessionStorage.setItem("qualityTab", JSON.stringify({newload: false, otclicked:true, stclicked: false}));
            setEmocAdvanceFilter(true);
            setQualityAdvanceFilter(false);
            advancedSearchDropdownFilter.classList.remove('d-none'); //Display adv. search dropdown filter
            setShowlearningKW("emoc");
            sidebarElem.style.pointerEvents = "all";
            sidebarElem.style.opacity = "1";
            document.querySelector(EMOC_SIDEBAR).style.display = 'block';
            document.querySelector(RISK_SIDEBAR).style.display = 'none';
            document.querySelector(QUALITY_SIDEBAR).style.display = 'none';
            utils.pageView('/pageview/emoc');
        } else {
            sessionStorage.setItem("emocTab", JSON.stringify({newload: false, otclicked:true, stclicked: false}));
            sessionStorage.setItem("qualityTab", JSON.stringify({newload: false, otclicked:false, stclicked: true}));
            sidebarElem.style.pointerEvents = "all";
            sidebarElem.style.opacity = "1";
            utils.pageView('/pageview/quality');
            setQualityAdvanceFilter(true);
            setEmocAdvanceFilter(false);
            document.querySelector(QUALITY_SIDEBAR).style.display = 'block';
            document.querySelector(EMOC_SIDEBAR).style.display = 'none';
            document.querySelector(RISK_SIDEBAR).style.display = 'none';
        }
    }

    // ================Add to report=======================================
    const [showReportModal, setShowReportModal] = useState(false);
    const [modalTypeSelection, setmodalTypeSelection] = useState("");
    const [tickedElement, setTickedElement] = useState(null);
    const [existingReportName] = useState([]);
    const [generateReportData, setGenerateReportData] = useState({ checked: false, id: "", title: "", type: "risk" })

    // ================Add to report of risk start=========================
    function reportCallback(data) {
        setmodalTypeSelection(data.type);
        if (data.currentElement.checked) {
            setTickedElement(data.currentElement);
            setGenerateReportData({ checked: true, id: data.currentElement.dataset.riskid, title: data.currentElement.dataset.risktitle, type: "risk" });
        } else {
            const riskid = tickedElement.dataset.riskid;
            setTickedElement(data.currentElement);
            setGenerateReportData({ checked: false, id: riskid, title: data.currentElement.dataset.risktitle, type: "risk" });
        }
    }
    function onReportModalOkClick(data) {
        setShowReportModal(data.close);
        if (modalTypeSelection === 'risk') {
            const query = document.querySelector(utils.elementSelectors.SEARCH_FIELD_SELECTOR).value,
                riskid = tickedElement.dataset.riskid,
                reportName = data.reportName;
            tickedElement.dataset.reportName = reportName;
            const risk_report = JSON.stringify({ query: query, risk_id: riskid, reportname: reportName });

            addToRiskLessonReports(risk_report, tickedElement, "/addtorisk", setShowReportModal);
        }
        if (modalTypeSelection === 'lesson') {
            const query = document.querySelector(utils.elementSelectors.SEARCH_FIELD_SELECTOR).value,
                lessonid = tickedElement.dataset.lessonid,
                reportName = data.reportName;
            tickedElement.dataset.reportName = reportName;
            const lesson_report = JSON.stringify({ query: query, ll_id: lessonid, reportname: reportName });

            addToRiskLessonReports(lesson_report, tickedElement, "/addtolessons", setShowReportModal);
        }
    }
    function onReportModalCancelClick(data) {
        tickedElement.checked = false;
        setShowReportModal(data.close);
    }

    // ================Add to report of risk end===========================
    // ================Add to report of lesson start=========================
    function reportLessonCallback(data) {
        setmodalTypeSelection(data.type);
        if (data.currentElement.checked) {
            setTickedElement(data.currentElement);
            setGenerateReportData({ checked: true, id: data.currentElement.dataset.lessonid, title: data.currentElement.dataset.lessontitle, type: "lesson" });
        } else {
            const lessonid = tickedElement.dataset.lessonid;
            setTickedElement(data.currentElement);
            setGenerateReportData({ checked: false, id: lessonid, title: data.currentElement.dataset.lessontitle, type: "lesson" });
        }
    }

    function heatmapFilterCallback(data) {
        heatmapFilterOnCall(data, filterCountsAndStatus, setFilterCountsAndStatus, OnHeatmapClick_ClearAll);
    }
    function OnHeatmapClick_ClearAll() {
        functionalTagClearFilter(true);
        advancedSearch({ projects: [], countries: [], lobs: [] }, filterCountsAndStatus, setFilterCountsAndStatus, setFilterValue, updateDropdownList, true);
        const lists = document.querySelectorAll(utils.elementSelectors.CHIP_LIST_SELECTOR);
        lists.forEach(function (element) {
            element.remove();
        });
        document.querySelector(utils.elementSelectors.MUST_INCLUDE_SELECTOR).value = "";
    }
    function stackedBarChartFIlterCallback(category) {
        stackedBarChartFIlterCallbackOnCall(category, filterCountsAndStatus, setFilterCountsAndStatus, OnHeatmapClick_ClearAll);
    }
    function stackedBarChartClearBtnClick() {
        stackedBarChartClearBtnOnClick(setFilterCountsAndStatus);
    }
    function dateFilterOnCallCallback(dateRange) {
        riskDateFilterOnCall(dateRange, filterCountsAndStatus, setFilterCountsAndStatus);
        setTimeout(() => {
            updateAdvancedSearchDropdown(document.querySelectorAll('.risk-item.d-block'), riskFilterDropdownUpdate);
        }, 1000);
    }
    // ================Add to report of lesson end=========================

    const [filterCountEmoc, setFilterCountEmoc] = useState({ emocCount: result.emoc.emoc_id.length });
    function emocFilter(data) {
        sessionStorage.setItem("emocTab", JSON.stringify({newload: true, otclicked:false, stclicked: false}));
        // if null clear the TECOP filter
        if (data !== null) {
            filterEmocResult(setFilterCountEmoc);
            setTimeout(() => {
                updateAdvancedSearchDropdown(document.querySelectorAll('.emoc-item.d-block'), eMoCFilterDropdownUpdate);
            }, 1000);
        } else {
            eMoCFunctionalTagClearFilter(filterCountEmoc, setFilterCountEmoc, setFilterValue, updateDropdownList)
            setTimeout(() => {
                updateAdvancedSearchDropdown(document.querySelectorAll('.emoc-item.d-block'), eMoCFilterDropdownUpdate);
            }, 1000);
        }
    }
    function eMoCdateFilterOnCallCallback(dateRange) {
        sessionStorage.setItem("emocTab", JSON.stringify({newload: true, otclicked:false, stclicked: false}));
        emocDateFilterOnCall(dateRange, setFilterCountEmoc);
        setTimeout(() => {
            updateAdvancedSearchDropdown(document.querySelectorAll('.emoc-item.d-block'), eMoCFilterDropdownUpdate);
        }, 1000);
    }
    const [filterCountQuality, setFilterCountQuality] = useState({ qualityCount: result.quality.quality_id.length });
    const [qualityFilterData, setQualityFilterData] = useState({
        quality_characteristics: result.quality.quality_characteristics,
        quality_discipline_function: result.quality.quality_discipline_function,
        quality_equipment_type: result.quality.quality_equipment_type
    });
    const qualityFiltersOnChange = useCallback( (value, filiterInputElement) => {
        sessionStorage.setItem("qualityTab", JSON.stringify({newload: true, otclicked:false, stclicked: false}));
        if (value !== '') {
            setFilterCountQuality(qualityCount => ({ ...qualityCount, qualityCount: getFilterResult(value, filiterInputElement) }));
            setTimeout(() => {
                updateAdvancedSearchDropdown(document.querySelectorAll('.quality-item.d-block'), qualityFilterDropdownUpdate);
                document.querySelector(QUALITY_PLOT_REFRESH).click();
                document.querySelector(QUALITY_PLOT_REFRESH).click();
            }, 1000);
        }
    }, [setFilterCountQuality, qualityFilterDropdownUpdate]);
    const qualityDateFilterOnCallCallback = useCallback((dateRange)=> {
        sessionStorage.setItem("qualityTab", JSON.stringify({newload: true, otclicked:false, stclicked: false}));
        qualityDateFilterOnCall(dateRange, setFilterCountQuality);
        setTimeout(() => {
            setQualityFilterData(getVisibleElementsFilterData());
            updateAdvancedSearchDropdown(document.querySelectorAll('.quality-item.d-block'), qualityFilterDropdownUpdate);
            document.querySelector(QUALITY_PLOT_REFRESH).click();
            document.querySelector(QUALITY_PLOT_REFRESH).click();
        }, 1000);
    },[qualityFilterDropdownUpdate]);

    const qualitySidebarFilterOnClear = () => {
        sessionStorage.setItem("qualityTab", JSON.stringify({newload: true, otclicked:false, stclicked: false}));
        qualityFilterOnClear();

        const filterItems = { projects: [], countries: [], lobs: [], selectedDropdown: "" };
        const chipLists = document.querySelectorAll(utils.elementSelectors.CHIP_LIST_SELECTOR);
        chipLists.forEach(element => {
            filterItems[element.dataset.type].push(element.dataset.item);
        });
        if(filterItems.projects.length > 0) {
            filterItems["selectedDropdown"] = 'projects';
        } else if(filterItems.countries.length > 0){
            filterItems["selectedDropdown"] = 'countries';
        } else if(filterItems.lobs.length > 0){
            filterItems["selectedDropdown"] = 'lobs';
        }

        qualityAdvancedSearch(filterItems, filterCountQuality, setFilterCountQuality, updateDropdownList, setQualityFilterData);
        
    }
    const [qualityProjectCountryLoB, setQualityProjectCountryLoB] = useState({
        lob: [...new Set(props.location.state.results.quality.quality_lob)],
        projects: [...new Set(props.location.state.results.quality.quality_org)],
        countries: [...new Set(props.location.state.results.quality.quality_loc)]
    });
    
    return (
        <div className="container-fluid px-0">
            <div className="d-flex align-items-stretch no-gutters">

                <div className="col app-layout-bg-color">
                    {/* =================Top Bar=============== */}
                    <header className="d-flex justify-content-between align-items-center no-gutters header-bar">
                        <div className='' style={{ marginLeft: '1rem' }}>
                            {/* <div className="d-flex justify-content-start align-items-center">
                                <div className="d-inline-block">
                                    <img className="logo" alt="" src={utils.logo}/>
                                </div>
                                <div className="d-inline-block">
                                    <h3 className="brand-name pt-3 ml-3">Qurie</h3>
                                </div>
                            </div> */}
                            <LogoBranding />
                        </div>
                        <div className="col-7">
                            <div className="my-1 main-page-search-field">
                                <Search isAdvancedSearch={true} searchCallback={handleSearchOnKeyDown} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center px-2 heaeder-menu">
                            <div className="mr-2 mt-1 download-report position-relative">
                                {/* <div><a className="download-report-toggle text-decoration-none" href="#downloadReport" data-toggle="collapse" aria-expanded="false">
                                    <img className="mr-1 download-report-toggle-icon" alt="" src={report} /></a></div> */}
                                <DownloadReport />
                            </div>
                            <div className="ml-2 mt-1 user-detail">
                                <UserProfile admin={adminInfo.isAdmin} appOwner={adminInfo.isAppOwner} otheruser={adminInfo.otheruser}/>
                            </div>
                        </div>
                    </header>
                    {/* =================Top Bar End=============== */}
                    <div className="container-fluid px-0">
                        <div className="d-flex">
                            <div className="sidebar-container tour-sidebar">
                                <SidebarMenu dataType={"risk"} items={items} count={filterCountsAndStatus.riskFunctionalCount} setFilterValue={setFilterValue}
                                    functionalTagClearFilter={functionalTagClearFilter} modifiedList={result.risk.risk_created_modified_date}
                                    dateFilterOnCall={dateFilterOnCallCallback} />
                                <SidebarMenu dataType={"emoc"}
                                    items={[
                                        { name: 'technical', label: 'Technical', items: [] },
                                        { name: 'economical', label: 'Economical', items: [] },
                                        { name: 'commercial', label: 'Commercial', items: [] },
                                        { name: 'organizational', label: 'Organizational', items: [] },
                                        { name: 'political', label: 'Political', items: [] }
                                    ]}
                                    count={{ "Technical": 0, "Economical": 0, "Commercial": 0, "Organizational": 0, "Political": 0 }} setFilterValue={setFilterValue}
                                    functionalTagClearFilter={functionalTagClearFilter} modifiedList={result.emoc.emoc_created_modified_date}
                                    dateFilterOnCall={eMoCdateFilterOnCallCallback} emocFilter={emocFilter} />
                                <SidebarMenu dataType={"quality"}
                                    items={[]}
                                    data={qualitydata}
                                    modifiedList={result.quality.quality_created_modified_date}
                                    dateFilterOnCall={qualityDateFilterOnCallCallback}
                                    qualitySidebarFilterOnClear = {qualitySidebarFilterOnClear}
                                    characteristicsOnChange={qualityFiltersOnChange}
                                    disciplineFunctionOnChange={qualityFiltersOnChange}
                                    equipmentTypeOnChange={qualityFiltersOnChange}
                                    dataFilterValues={qualityFilterData}
                                    />
                            </div>
                            <div className="col-7 mt-2">
                                <div className="la">
                                    {
                                        (learnActionInfo.display ? <LearnAction data={learnActionInfo}  parentCallback={callBackLearnActionToClose} /> : null)
                                    }
                                </div>
                                <div className={learnActionInfo.display ? 'tab-wrapper d-none' : 'tab-wrapper d-block'} style={{ transition: "all 1s ease 0s" }}>
                                    <ul className="nav nav-tabs tour-data-tabs" id="mainLayoutTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link active" id="risk-tab" data-toggle="tab" href="#risk" role="tab" aria-controls="risk" aria-selected="true"
                                                onClick={mainTabsOnClick}>Risk</a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="learnings-tab" data-toggle="tab" href="#learnings"
                                                role="tab" aria-controls="learnings" aria-selected="false"
                                                onClick={mainTabsOnClick}>Learnings</a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="emoc-tab" data-toggle="tab" href="#emoc" role="tab" aria-controls="emoc" aria-selected="false"
                                                onClick={mainTabsOnClick}>eMoC</a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="quality-tab" data-toggle="tab" href="#quality" role="tab" aria-controls="quality" aria-selected="false"
                                                onClick={mainTabsOnClick}>Quality</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="mainLayoutTabContent">
                                        <div className="tab-pane fade show active" id="risk" role="tabpanel" aria-labelledby="risk-tab">
                                            <div className="">
                                                {/* ==============================Graph Analysis===================== */}
                                                {graphAnalysis(costgraph, schedulegraph, probabilitygraph, heatmapgraph, riskids,shellESummary,shellESummaryLoading, setHeatmapgraphData,
                                                    heatmapFilterCallback, stackedBarChartFIlterCallback, riskPlotTabsOnClick)}
                                                {/* ==============================Graph Analysis End===================== */}
                                                {/* ==============================Search Results Start===================== */}
                                                {riskResults(result, filterCountsAndStatus, callBack, reportCallback, generateReportData, generateRportBtnRef)}
                                                {/* ==============================Search Results End===================== */}
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="learnings" role="tabpanel" aria-labelledby="learnings-tab">
                                            {learningResults(result, reportLessonCallback, generateReportData)}
                                        </div>
                                        <div className="tab-pane fade" id="emoc" role="tabpanel" aria-labelledby="emoc-tab">
                                            <Emoc data={emocdata} filterCount={filterCountEmoc.emocCount} />
                                        </div>
                                        <div className="tab-pane fade" id="quality" role="tabpanel" aria-labelledby="quality-tab">
                                            <Quality data={qualitydata} filterCount={filterCountQuality.qualityCount} associationsOnClick={callBack} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right-sidebar">
                                <SearchAdvanced className="" projects={projects} countries={countries} lob={lob}
                                    advanceSearchCallback={advanceSearchCallback} mustIncludeCallback={mustIncludeCallback}
                                    emocAdvanceFilter={emocAdvanceFilter} emocProjectCountryLoB={emocProjectCountryLoB} 
                                    qualityAdvanceFilter={qualityAdvanceFilter} qualityProjectCountryLoB={qualityProjectCountryLoB}
                                    />
                                <div className='tour-alternate-searchterm'>
                                {
                                    alternateKeyword(showlearningKW, keyWordsQueryCallback, riskkw, learningkw, emockw)
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading ? <RaceSpinner /> : null}
            <ReportModal show={showReportModal} reports={existingReportName} onReportModalOkClick={onReportModalOkClick} onReportModalCancelClick={onReportModalCancelClick} />
            <BowtiePopup show={bowtieoption.show} bowtieCallback={bowtieCallback} data={bowtieoption.data} datatype={bowtieoption.datatype}
                bowtieAfterClose={bowtieAfterClose} thematicBowtie={bowtieoption.thematic} dataid={bowtieoption.dataid} />
            <IdleTimeTracker />
        </div>
    );
}
